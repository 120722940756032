<template>
  <canvas id="snowflakes" />
</template>

<script>
  import Vue from 'vue'
  import VueConfetti from 'vue-confetti'
  import flakePattern01 from '@/assets/images/snowflake-01.png'
  import flakePattern02 from '@/assets/images/snowflake-02.png'
  import flakePattern03 from '@/assets/images/snowflake-03.png'
  import flakePattern04 from '@/assets/images/snowflake-04.png'
  Vue.use(VueConfetti)
  export default {
    mounted() {
      this.$nextTick(() => {
        const canvas = document.getElementById("snowflakes")
        canvas.width = canvas.parentElement.clientWidth;
        canvas.height = canvas.parentElement.clientHeight;
        this.start();
      });
    },
    methods: {
      start() {
        this.$confetti.start({
            canvasId: 'snowflakes',
            defaultDropRate: 5,
            defaultSize: 10,
            particlesPerFrame: 0.90,
            windSpeedMax: 5,
            particles: [
                {
                  type: 'image',
                  url: flakePattern01
                },
                {
                  type: 'image',
                  url: flakePattern02
                },
                {
                  type: 'image',
                  url: flakePattern03
                },
                {
                  type: 'image',
                  url: flakePattern04
                },
            ],
        });
      }
    }
  }
</script>

<style lang="sass" scoped>

#snowflakes
  position: absolute
  left: 0
  top: 0
  z-index: 500
  pointer-events: none

</style> 