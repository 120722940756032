class Particle {
  constructor(canvas, config, x, y) {
    this.x = x || Math.round(Math.random() * canvas.width);
    this.y = y || Math.round(Math.random() * canvas.height);

    this.c = config.colors[Math.floor(Math.random() * config.colors.length)];

    // Speed
    this.s = Math.pow(Math.ceil(Math.random() * config.maxSpeed), .7);

    // Direction
    this.d = Math.round(Math.random() * 20);

    // Polygon edges
    this.px1 = Math.floor(Math.random() * 10) + config.maxParticleSize;
    this.py1 = Math.floor(Math.random() * 10) + config.maxParticleSize;
    this.px2 = this.px1 - Math.floor(Math.random() * 20) + config.maxParticleSize;
    this.py2 = this.py1 + Math.floor(Math.random() * 20) + config.maxParticleSize;
    this.px3 = this.px2 - Math.floor(Math.random() * 30) + config.maxParticleSize;
    this.py3 = this.py2 - Math.floor(Math.random() * 30) + config.maxParticleSize;
    this.px4 = this.px3 - Math.floor(Math.random() * 5) + config.maxParticleSize;
    this.py4 = this.py3 - Math.floor(Math.random() * 5) + config.maxParticleSize;

    // We start with no transparency
    this.a = 1.0;
  }
}

export default {
  data() {
    return {
      config: {
        particleNumber: 100,
        maxParticleSize: 8,
        maxSpeed: 180,      
        colors: [
          "#deebf6", 
          "#90c0d7",
          "#2a83ba"
        ],
      },
      particles: [],
      centerX: 0,
      centerY: 0,
      canvas: null,
      ctx: null
    };
  },
  mounted() {
    this.canvas = document.getElementById("sparkleCanvas")
    this.ctx = this.canvas.getContext('2d')

    this.canvas.width = this.canvas.parentElement.clientWidth
    this.canvas.height = this.canvas.parentElement.clientHeight
    this.centerX = this.canvas.width / 2
    this.centerY = this.canvas.height / 2

    this.frame()

    const pieces = document.querySelectorAll('.piece')
    pieces.forEach(piece => {
      piece.addEventListener('click', this.sparkle, false);
    });
  },
  destroyed() {
    const pieces = document.querySelectorAll('.piece')
    pieces.forEach(piece => {
      piece.removeEventListener('click', this.sparkle);
    });
  },
  methods: {
    updateParticleModel(p) {
      var a = 10; // find the 3rd angle
      if (p.d > 0 && p.d < 180) {
        p.x += p.s * Math.sin(p.d) / Math.sin(p.s)
      } else {
        p.x -= p.s * Math.sin(p.d) / Math.sin(p.s)
      }

      if (p.d > 90 && p.d < 270) {
        p.y += p.s * Math.sin(a) / Math.sin(p.s)
      } else {
        p.y += p.s * Math.sin(a) / Math.sin(p.s)
      }

      var alpha = Math.random() / 20
      p.a - alpha > 0 ? p.a -= alpha : p.a = 0;
    
      return p;
    },

    drawParticle(x, y, c, a, px1, py1, px2, py2, px3, py3, px4, py4) {
      this.ctx.globalAlpha = a;
      this.ctx.beginPath();
      this.ctx.fillStyle = c;
      this.ctx.moveTo(x, y);
      this.ctx.lineTo(x + px1, y + py1);
      this.ctx.lineTo(x + px2, y + py2);  
      this.ctx.lineTo(x + px3, y + py3);  
      this.ctx.lineTo(x + px4, y + py4);  
      this.ctx.closePath();
      this.ctx.fill();
      this.ctx.globalAlpha = 1.0;
    },

    cleanUpArray() {
      this.particles = this.particles.filter((p) => { 
        return (p.x > -100 && p.y > -100); 
      });
    },

    initParticles(numParticles, x, y) {
      for (let i = 0; i < numParticles; i++) {
        this.particles.push(new Particle(this.canvas, this.config, x, y));
      }
      this.particles.forEach((p) => {
        this.drawParticle(p.x, p.y, p.r, p.c, p.a, p.px1, p.py1, p.px2, p.py2, p.px3, p.py3, p.px4, p.py4);
      });
    },

    frame() {
      // Clear background first
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      // Update Particle models to new position
      this.particles.map((p) => {
        return this.updateParticleModel(p);
      });
      // Draw em'
      this.particles.forEach((p) => {
          this.drawParticle(p.x, p.y, p.c, p.a, p.px1, p.py1, p.px2, p.py2, p.px3, p.py3, p.px4, p.py4);
      });
    
      window.requestAnimationFrame(this.frame);
    },

    sparkle(event) {
      this.cleanUpArray();
      const x = event.clientX - this.canvas.getBoundingClientRect().left
      const y = event.clientY - this.canvas.getBoundingClientRect().top
      this.initParticles(this.config.particleNumber, x, y);
    }
  },
};
